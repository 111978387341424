.custom-pagination .awsui_page-item_fvjdu_nfftc_252:is(.dark *) {
  /* background-color: #818CF8!important; */
  border-radius: 4px;
  padding: 2rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.custom-pagination .awsui_page-item_fvjdu_nfftc_252 button:is(.dark *) {
  /* background-color: #818CF8!important; */
  border-radius: 4px;
  padding: 0.5rem !important;
  color: white !important;
  text-align: center;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0.25rem !important;
    height: 15px !important;
  }
}
.custom-pagination .awsui_button-current_fvjdu_nfftc_235:is(.dark *) {
  /* background-color: #818CF8!important; */
  font-weight: 800;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.custom-pagination .awsui_page-item_fvjdu_nfftc_252 button span {
  text-align: center;
  height: 20px;
  @media (max-width: 768px) {
    height: 10px;
  }
}

.custom-pagination .awsui_page-item_fvjdu_nfftc_252 {
  /* background-color: #818CF8!important; */
  border-radius: 4px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-pagination .awsui_page-item_fvjdu_nfftc_252 button {
  /* background-color: #818CF8!important; */
  border-radius: 4px;
  padding: 0.5rem !important;
  text-align: center;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0.25rem !important;
  }
}
.custom-pagination .awsui_button-current_fvjdu_nfftc_235 {
  /* background-color: #818CF8!important; */
  font-weight: 800;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.custom-pagination .awsui_page-item_fvjdu_nfftc_252 button span {
  text-align: center;
  height: 20px;
}

.integration-logos {
  filter: brightness(0) saturate(100%) invert(87%) sepia(12%) saturate(22%)
    hue-rotate(314deg) brightness(88%) contrast(80%);
}

.custom-copy .awsui_text-to-copy_ljpwc_1spew_9:is(.dark *) {
  color: whitesmoke;
}
.custom-copy .awsui_text-to-copy_ljpwc_1spew_9 {
  color: black;
}

:root {
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 0 0 calc(1px) hsla(0, 0%, 100%, 0.05);
}
.custom-shadow {
  -webkit-box-shadow: -3px 4px 4px 0px rgba(255, 255, 255, 0.39) !important;
  box-shadow: -3px 4px 4px 0px rgba(255, 255, 255, 0.39) !important;
}

.custom-container {
  background-color: #2f2f2f !important;
}
.custom-container:is(.dark *) {
  background-color: #3f4344 !important;
}

.custom-container-card {
  /* border: 1px solid #dcdcdc!important; */
  background-color: #5c5c5cd5 !important;
  border: none;

  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)!important; */
  border-radius: 8px !important;
  /* padding: 20px!important; */
  /* margin: 15px 0!important; */
}
.custom-container-card h2 {
  /* font-size: 1.5rem!important; */
  font-weight: bold !important;
  color: #ffff !important;
}
.custom-container-card p {
  /* font-size: 1rem!important; */
  color: #fff !important;
  /* line-height: 1.6!important; */
}

.custom-container-card:is(.dark *) {
  background-color: #e4e3e3 !important;
  /* border: 1px solid #000!important; */
  /* box-shadow: -5px 5px 11px 10px rgb(255 255 255 / 20%) !important; */
  border-radius: 8px !important;
  /* padding: 20px!important; */
  /* margin: 15px 0!important; */
}
.custom-container-card h2:is(.dark *) {
  /* font-size: 1.5rem!important; */
  font-weight: bold !important;
  color: #000 !important;
}
.custom-container-card p:is(.dark *) {
  /* font-size: 1rem!important; */
  color: #000 !important;
  line-height: 1.6 !important;
}
.custom-button {
  min-height: 50px;
}

.custom-pg-bar {
  margin-top: 10px;
  border-radius: 1px;
  /* min-height: 10px; */
  height: 1px;
}
.custom-pg-bar-var {
  transition: all ease-in 0.5s;
  border-radius: 1px;
}

.custom-pg-bar.active {
  .custom-pg-bar-var {
    height: 8px;
  }
  height: 8px;
}
.text-indigo-500 {
  color: rgb(99, 102, 241) !important;
}

.custom-tabs .awsui_tabs-tab-label_14rmt_1ebqu_348 span {
  color: #000 !important;
}
.custom-tabs
  .awsui_tabs-tab-focusable_14rmt_1ebqu_510
  .awsui_tabs-tab-active_14rmt_1ebqu_499
  span
  :is(.dark *) {
  color: #006ce0 !important;
}
.card-icons {
  fill: #000 !important;
}
.card-icons:is(.dark *) {
  fill: #fff !important;
}

.iframe-div {
  position: relative;
  box-sizing: content-box;
  max-height: 80vh;
  max-height: 80svh;
  width: 100%;
  aspect-ratio: 1.957703927492447;
  padding: 25px 0 40px 0;
}

.iframe-div1 {
  position: relative;
  box-sizing: content-box;
  max-height: 80vh;
  max-height: 80svh;
  width: 100%;
  aspect-ratio: 1.957703927492447;
  padding: 10px 0 40px 0;
}

.iframe-div-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
}

.custom-dark-pagination .awsui_page-item_fvjdu_nfftc_252 {
  /* background-color: #818CF8!important; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.custom-dark-pagination .awsui_page-item_fvjdu_nfftc_252 button {
  /* background-color: #818CF8!important; */
  color: white !important;
}
.custom-dark-pagination .awsui_button-current_fvjdu_nfftc_235 {
  /* background-color: #818CF8!important; */
  font-weight: 800;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

#or-sprator::before{
  width: 3px;
  background: #6366F1;
  height: 100%;
  content: '';
  border-radius: 7px;
  position: absolute;
  right: 50%;
  top:-100%

}
#or-sprator::after{
 width: 3px;
  background: #6366F1;
  height: 100%;
  border-radius: 7px;
  content: '';
  position: absolute;
  right: 50%;
  top:100%


}


.cards {
  width: 100%;
  height: fit-content;
  /* border: 1px solid red; */
  position: relative;
  /* margin-top: 32rem!important; */
}


.card {
  width: 100%;
  height: 100%;
  /* position: absolute; */
  top: 50%;
  left: 0;
  right: 0;
  border-radius: 10px;
}

.cards-section{
  padding-top: 5rem!important;
}


  @media (max-width: 768px) {
    .cards-section{
      padding-top: 5rem!important;
    }
  }

  .anchor-link{
    margin-left: -2rem!important;
  }



  /* .markdown-container .markdown-body {
  all: unset; 
} */
.markdown-body ol,
.markdown-body ul,
.markdown-body menu {
  all: revert!important; /* Reverts styles to browser defaults */
}