
.schema .awsui_list_l0dv0_1qapx_222{
    max-height: calc(62dvh - 7rem);
    overflow: hidden;
    overflow-y: auto;
}


.schema .awsui_content-wrapper_14iqq_96qba_305{
    min-height: calc(62dvh - 1.75rem);
    max-height: calc(62dvh - 1.75rem);
    /* overflow: hidden; */
    overflow-y: auto;

}
.schema .awsui_wrapper_wih1l_ez3lf_212{
     min-height: calc(62dvh - 7rem);
     /* max-height: calc(62dvh - 1.75rem); */
    /* overflow: hidden; */
    /* overflow-y: scroll; */
}
.schema .awsui_header-controls_wih1l_1200x_321{
    padding-left: 0!important;
}
.schema .awsui_root_l0dv0_1qapx_145{
    background: #e4e3e3!important;
}
.schema .awsui_root_wih1l_ez3lf_153{
    background: #e4e3e3!important;
}
.schema .awsui_root_2qdw9_1rn64_181{
    background: #e4e3e3!important;
}

.schema .awsui_content_14iqq_96qba_305{
    background: #e4e3e3!important;
}
.schema .awsui_header_14iqq_96qba_346{
    background: #e4e3e3!important;
}
.schema th{
    background: #e4e3e3!important;
}
.schema .awsui_header_l0dv0_1behr_178{
    padding-top: 1rem!important;
}
.schema .awsui_header_l0dv0_1behr_178 span{
   font-size: 20px!important;
}

/* .awsui_tabs-tab-label_14rmt_1tm44_338 span :is(.dark *) {
    color: white!important;
} */
.schema-page .awsui_tabs-tab-focusable_14rmt_1ebqu_510 span  :is(.dark *) {
    color: white!important;
}

.schema-page .awsui_tabs-tab-focusable_14rmt_1ebqu_510 .awsui_tabs-tab-active_14rmt_1ebqu_499 span  :is(.dark *) {
    color: #006ce0!important;
}